import { createSlice } from "@reduxjs/toolkit";

const guestSlice = createSlice({
    name: 'guest',
    initialState: {
        data: [],
        message: null,
        loading: false,
        create: null,
        error: {
            status: false,
            message: null
        }
    },
    reducers: {
        setGuest: (state, action) => {
            state.data = action.payload
            state.loading = false
            state.message = null
            state.error = {
                status: false,
                message: null
            }
        }
    }
})

export const { setGuest  } = guestSlice.actions
export default guestSlice.reducer