import { Pane } from "evergreen-ui"
import "./index.css";
import { useEffect, useRef, useState } from "react";


function Background() {
	
	const interBubbleRef = useRef<HTMLDivElement>(null);
  const [curX, setCurX] = useState(0);
  const [curY, setCurY] = useState(0);
  // const [tgX, setTgX] = useState(0);
  // const [tgY, setTgY] = useState(0);

	const tgX = useRef(0);
	const tgY = useRef(0);

	
	useEffect(() => {
		function move() {
			setCurX((prevCurX) => prevCurX + (tgX.current - prevCurX) / 20);
			setCurY((prevCurY) => prevCurY + (tgY.current - prevCurY) / 20);
	
			if (interBubbleRef.current) {
				interBubbleRef.current.style.transform = `translate(${Math.round(curX)}px, ${Math.round(curY)}px)`;
			}
	
			requestAnimationFrame(move);
		}
	
		const handleMouseMove = (event: MouseEvent) => {
			tgX.current = event.clientX;
			tgY.current = event.clientY;
		};
	
		window.addEventListener('mousemove', handleMouseMove);
	
		move();
	
		return () => {
			window.removeEventListener('mousemove', handleMouseMove);
		};
	}, [curX, curY]);



	return (
			<Pane className="gradient-bg">
				<svg xmlns="http://www.w3.org/2000/svg">
				<defs>
					<filter id="goo">
						<feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
						<feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -8" result="goo" />
						<feBlend in="SourceGraphic" in2="goo" />
					</filter>
				</defs>
			</svg>
			<div className="gradients-container">
				<div className="g1"></div>
				<div className="g2"></div>
				<div className="g3"></div>
				<div className="g4"></div>
				<div className="g5"></div>
				<div className="interactive" ref={interBubbleRef}></div>
			</div>
			</Pane>
	)
}

export default Background