import { configureStore } from "@reduxjs/toolkit";
import guestReducer from "./reducers/guest.reducer";

const store = configureStore({
    reducer: {
			guest: guestReducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    }),
})

export default store;