import { Pane } from "evergreen-ui";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/home";
import Background from "components/background";

function App() {

  return (
    <Pane>
      <Background />
      <Routes>
        <Route path="/" element={ <Home /> } />
      </Routes>
    </Pane>

  );  
}

export default App;
