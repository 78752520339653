import { Button, Checkbox, Pane, Text, TextInput  } from "evergreen-ui"
import "./index.css";
import { useState } from "react";
import logo from '../../assets/images/logo.svg';
import { ReactComponent as SexMale } from '../../assets/icons/man.svg';
import { ReactComponent as SexWoman } from '../../assets/icons/woman.svg';
import { ReactComponent as UploadIcon } from '../../assets/icons/upload.svg';
import { ReactComponent as FBIcon } from '../../assets/icons/fb.svg';
import { ReactComponent as InstagramIcon } from '../../assets/icons/instagram.svg';
import { ReactComponent as LinkedInIcon } from '../../assets/icons/linkedin.svg';



function Home() {

	const [showBlock, setShowBlock ]= useState(false);
	const [privacyCheck, setPrivacyCheck] = useState(false);
	const [activeSex, setActiveSex] = useState('');
	const [activeSoc, setActiveSoc] = useState('');

  const handleSexClick = (sex: string) => {
    setActiveSex((prevSex: string) => (prevSex === sex ? '' : sex));
  };

	const handleSocClick = (soc: string) => {
		setActiveSoc((prevSoc: string) => (prevSoc === soc ? '' : soc));
	}

	const Input = ({name, className = ''}: {name: string, className?: string}) => {
		return (
				<TextInput placeholder={ name } className={`${className} placeholder:!text-white !text-white focus:!ring-0 isolate overflow-hidden !border-none !rounded-full !backdrop-blur !transition-colors !bg-white/10 supports-backdrop-blur:!bg-white/95`} />
		)
	}

	return (
		<Pane className="home">
			<Pane className={`block-1 ${showBlock ? 'hide' : ''}`} onClick={() => setShowBlock(true)}>
				<img src={logo} alt="logo" />
			</Pane>
			<Pane className={`block-2 ${showBlock ? 'show' : ''} isolate overflow-hidden border-color border border-[#ffffff1a] backdrop-blur transition-colors bg-white/10 supports-backdrop-blur:bg-white/95`}>
				<Pane className="flex flex-row justify-between items-center">
					<Pane>
						<Pane className="absolute -mt-6 opacity-60 text-xl">Event</Pane>
						<Pane className="text-3xl">Sign up</Pane>
					</Pane>
					{/* <img src={logo} alt="logo" className="w-[60px] absolute right-[40px] top-[20px]" /> */}
				</Pane>
				<Pane className="mt-[30px] flex flex-col gap-[18px]">

					<Pane className="flex gap-[5px]">
						<Pane className={` sex-icon min-w-[34px] cursor-pointer flex justify-center items-center px-[10px] py-[7px] rounded-[5px] isolate overflow-hidden border-color backdrop-blur transition-colors  supports-backdrop-blur:bg-white/95 ${activeSex !== 'woman' ? 'show ' : 'hide bg-white/10'} `} onClick={() => handleSexClick('man')}
						>
							<SexMale />
						</Pane>
						<Pane className={`sex-icon min-w-[30px] cursor-pointer flex justify-center items-center px-[10px] py-[7px] rounded-[5px] isolate overflow-hidden border-color backdrop-blur transition-colors  supports-backdrop-blur:bg-white/95 ${activeSex !== 'man' ? 'show ' : 'hide bg-white/10'} `} onClick={() => handleSexClick('woman')}
						>
							<SexWoman  />
						</Pane>
						<Input name="Name" className={`${activeSex === 'man' || activeSex === 'woman' ? '!w-[72%]' : '!w-[60%]'}`} />
					</Pane>

					<Pane className="flex flex-row gap-[10px]">
						<Pane className="flex flex-col gap-[18px] w-[70%]">
							<Input name="Surname" className="!w-auto" />
							<Input name="Personal ID" className="!w-auto" />
						</Pane>
						<Pane className="-mt-2 select-none cursor-pointer flex flex-col justify-center items-center text-white gap-1 min-w-[97px] min-h-[97px] rounded-full isolate overflow-hidden border-color backdrop-blur transition-colors bg-white/10 supports-backdrop-blur:bg-white/95">
							<UploadIcon className="w-[37px]" />
							<Text className="!text-white">Upload</Text>
						</Pane>
					</Pane>

					<Input name="+995" className="!-mt-2" />

					<Pane className={`flex gap-[5px] items-center`}>
						<Pane className={`cursor-pointer social min-w-[30px] ${activeSoc === 'fb' || activeSoc === '' ? 'show' : 'hide'}`} onClick={() => handleSocClick('fb')}>
							<FBIcon className={` fill-white/30 `} />
						</Pane>
						<Pane className={`cursor-pointer social min-w-[30px] ${activeSoc === 'inst' || activeSoc === '' ? 'show' : 'hide'}`} onClick={() => handleSocClick('inst')}>
							<InstagramIcon />
						</Pane>
						<Pane className={`cursor-pointer social min-w-[30px] ${activeSoc === 'linkedin' || activeSoc === '' ? 'show' : 'hide'}`} onClick={() => handleSocClick('linkedin')}>
							<LinkedInIcon />
						</Pane>
						<Pane>
							<Input name="Social media link" className={`${ activeSoc === 'fb' || activeSoc === 'inst' || activeSoc === 'linkedin' ? '!w-[154%]' : '!w-[110%]'} `} />
						</Pane>
					</Pane>

					<Input name="Profession (Optional)" />

					<Pane className="flex flex-row gap-2">
						<Checkbox className={`!p-0 !m-0 ${privacyCheck ? '!opacity-100' : '!opacity-40'} `} onChange={() => setPrivacyCheck(!privacyCheck)}  />
						<Text className="!text-white !opacity-4 cursor-pointer hover:!opacity-100">Privacy Policy</Text>
					</Pane>

					<Button className="w-auto !rounded-full	!self-end">
						Sign Up
					</Button>
				</Pane>
			</Pane>
		</Pane>
	)
}

export default Home